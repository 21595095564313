import { useQueryClient } from '@tanstack/vue-query';
import type { GetVersionedTransactionConfig, Finality } from '@solana/web3.js';
import { solApi } from '@/shared/api';
import { getTransactionDetailsQueryKey } from './keys';

export const useGetTransactionDetailsQuery = () => {
  const queryClient = useQueryClient();

  const refetch = async (
    chainId: string | undefined,
    transactionId: string | undefined,
    config: GetVersionedTransactionConfig | Finality | undefined,
  ) => {
    const queryKey = getTransactionDetailsQueryKey(
      chainId,
      transactionId,
      config,
    );
    const data = await queryClient.fetchQuery({
      queryKey,
      queryFn: async () => {
        if (!transactionId || !chainId) {
          return;
        }
        const connection = solApi.rpc.getConnection(chainId);

        const result = await connection.getParsedTransaction(
          transactionId,
          config,
        );

        if (!result) {
          throw new Error('Transaction not found');
        }
        return result;
      },
      retry: 20,
      retryDelay: 1000,
    });
    return data;
  };

  return { refetch };
};
