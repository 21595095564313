<script lang="ts" setup>
  import { computed, toRefs, ref, toRef } from 'vue';
  import { PermissionFlags, hasPermission, isPortalOwner } from '@/shared/lib';
  import { ROLE_KIND_FIELD_EVERYONE, USER_CONTENT } from '@/common';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import type {
    MemberListItemView,
    PortalView,
    RoleListItemView,
    RoleView,
  } from 'dfx/edge/edge.did';
  import { Diamond } from '@/shared/ui/diamond';
  import {
    useUser,
    useGetAssignableUserPortalRolesQuery,
  } from '@/entities/user';
  import {
    useAddMemberRoleMutation,
    useRemoveMemberRoleMutation,
  } from '@/entities/portal';

  const props = defineProps<{
    portalView: PortalView;
    member: MemberListItemView;
  }>();

  const emits = defineEmits<{
    (e: 'ban-member'): void;
    (e: 'kick-member'): void;
    (e: 'refetch'): void;
  }>();

  const { member } = toRefs(props);
  const showUserBanOptions = ref(false);
  const showUserRoleOptions = ref(false);

  const { data: currentPortalUserAssignableRoles } =
    useGetAssignableUserPortalRolesQuery(
      toRef(() => props.portalView),
      true,
    );
  const { mutate: addMemberRoleMutation } = useAddMemberRoleMutation();
  const { mutate: removeMemberRoleMutation } = useRemoveMemberRoleMutation();
  const { currentUser } = useUser();

  const assignableRoles = computed(() =>
    currentPortalUserAssignableRoles.value?.filter(
      (role: RoleView) => !(ROLE_KIND_FIELD_EVERYONE in role.kind),
    ),
  );

  const availableRolesToAssign = computed(() => {
    return assignableRoles.value?.filter(
      (role: RoleView) => !isMemberRole(role),
    );
  });

  const isMemberRole = (role: RoleListItemView) =>
    member.value.roles.some(
      (memberRole: RoleListItemView) => memberRole.id == role.id,
    );

  const addMemberRole = (role: RoleListItemView) => {
    addMemberRoleMutation(
      {
        portalId: props.portalView.id,
        memberId: member.value.id,
        roleId: role.id,
      },
      {
        onSuccess: () => {
          emits('refetch');
        },
      },
    );
  };

  const removeMemberRole = (role: RoleListItemView) => {
    removeMemberRoleMutation(
      {
        portalId: props.portalView.id,
        memberId: member.value.id,
        roleId: role.id,
      },
      {
        onSuccess: () => {
          emits('refetch');
        },
      },
    );
  };
</script>

<template>
  <div class="flex items-center py-3 text-white border-b border-gray-700">
    <user-avatar
      v-if="member.user"
      :item="member.user"
      size="w-8 h-8"
      class="mr-2.5"
    />
    <base-icon v-else name="user-circle" size="w-8 h-8" class="mr-2.5" />
    <div class="flex-auto truncate">
      <router-link
        :to="{
          name: USER_CONTENT,
          params: { username: member.user.username },
        }"
      >
        {{ member.user.username }}
      </router-link>
    </div>
    <div class="flex items-center w-min md:w-max">
      <div class="flex flex-row flex-wrap justify-end">
        <div
          v-for="role in member.roles"
          :key="member.id + '-' + role.id"
          class="relative flex p-2 my-1 ml-2 text-xs bg-gray-700 rounded-lg w-max group"
        >
          <span
            v-if="
              !(
                role.name === 'Admin' && !isPortalOwner(portalView, currentUser)
              )
            "
            class="absolute p-1 z-1 text-white bg-gray-600 rounded-lg opacity-0 cursor-pointer left-1 group-hover:opacity-100 hover:bg-gray-950"
            @click="removeMemberRole(role)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-2 h-2"
              viewBox="0 0 24 24"
            >
              <path
                class="fill-current"
                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
              />
            </svg>
          </span>
          <diamond :color="role.color" size="size-2" class="mt-1 mr-2" />
          {{ role.name }}
        </div>
      </div>
      <div
        class="relative p-2 ml-3 md:ml-2 text-xs bg-gray-700 rounded-lg hover:bg-gray-900"
        @click="showUserRoleOptions = !showUserRoleOptions"
      >
        <base-icon name="plus" size="w-5 h-5" />
        <div
          v-if="showUserRoleOptions"
          ref="userRoleOptions"
          class="absolute right-0 z-30 px-4 py-2 mt-4 bg-gray-700 rounded-lg min-w-max"
        >
          <span
            v-show="availableRolesToAssign?.length"
            class="text-sm text-gray-400 opacity-50 hover:text-white"
          >
            No Available Roles
          </span>
          <p
            v-for="role in availableRolesToAssign"
            :key="role.id.toString()"
            class="flex items-center py-3 text-white cursor-pointer"
            @click="addMemberRole(role)"
          >
            <diamond :color="role.color" size="size-2" class="mr-2" />
            <span class="text-sm text-gray-400 hover:text-white">
              {{ role.name }}
            </span>
          </p>
        </div>
      </div>
      <div
        v-if="
          hasPermission(portalView.perm, PermissionFlags.BAN_MEMBER) ||
          hasPermission(portalView.perm, PermissionFlags.KICK_MEMBER)
        "
        class="relative p-1.5 ml-3 md:ml-2 text-xs bg-gray-700 rounded-lg hover:bg-gray-900"
        @click="showUserBanOptions = !showUserBanOptions"
      >
        <base-icon name="ellipsis" size="w-5 h-5" />
        <div
          v-if="showUserBanOptions"
          class="absolute right-0 z-30 mt-4 bg-gray-700 rounded-lg min-w-max"
        >
          <div
            v-if="
              hasPermission(portalView.perm, PermissionFlags.BAN_MEMBER) &&
              !isPortalOwner(portalView, member.user)
            "
            class="flex items-center px-4 py-3 font-medium rounded-t-lg cursor-pointer hover:bg-gray-600"
            @click="emits('ban-member')"
          >
            <base-icon name="outline-block" size="size-5" class="mr-2" />
            Ban {{ member.user.username }}
          </div>
          <template
            v-if="hasPermission(portalView.perm, PermissionFlags.KICK_MEMBER)"
          >
            <div
              v-if="!isPortalOwner(portalView, member.user)"
              class="flex items-center px-4 py-3 font-medium rounded-b-lg cursor-pointer hover:bg-gray-600"
              @click="emits('kick-member')"
            >
              <base-icon name="remove" size="size-5" class="mr-2" />
              Remove {{ member.user.username }}
            </div>
            <div v-else class="p-4 text-sm text-gray-400">
              Cannot remove portal owner
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
