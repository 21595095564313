<script setup lang="ts">
  import { computed, ref, toRef } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { WalletSendTransactionError } from '@solana/wallet-adapter-base';
  import type {
    Finality,
    GetVersionedTransactionConfig,
    ParsedTransactionWithMeta,
    Transaction,
    VersionedTransaction,
  } from '@solana/web3.js';
  import { useWallet } from 'solana-wallets-vue';
  import { useToast } from '@/shared/model';
  import { DSCVR_STATIC_ASSETS_CDN_URL, SOLANA_FM_PREFIX } from '@/common';
  import {
    fetchMedia,
    SOLANA_WALLET_NAMES,
    isUserRejectionSolanaError,
    shortenString,
  } from '@/shared/lib';
  import { Loader } from '@/shared/ui/loader';
  import { dscvrApi } from '@/shared/api';
  import { useTransactionScan } from '../model/composables/use-transaction-scan';
  import { useGetTransactionDetailsQuery } from '../api/use-get-transaction-details.query';
  import { useSignAndSendTransaction } from '../api/use-sign-and-send-transaction.mutation';
  import TransactionScan from './TransactionScan.vue';

  const props = defineProps<{
    chainId: string;
    unsignedTx: Transaction | VersionedTransaction;
    scanResponse: dscvrApi.blowfish.ScanTransactionsSolana200Response;
    skipSendToBlockchain?: boolean;
    txSnapshotImageUrl?: string;
    awaitCommitment?: Finality;
  }>();

  const emit = defineEmits<{
    (e: 'close'): void;
    (
      e: 'confirm',
      transactionId?: string,
      details?: ParsedTransactionWithMeta,
    ): void;
  }>();

  const isFetchingTransactionDetails = ref(false);

  const { t } = useI18n({ useScope: 'global' });
  const { wallet } = useWallet();
  const { showToast } = useToast();
  const { warnings, warningsType } = useTransactionScan(
    toRef(props, 'scanResponse'),
  );
  const { refetch: getTransactionDetails } = useGetTransactionDetailsQuery();
  const {
    mutate: signWalletTransactionMutation,
    isPending: isSigningTransaction,
  } = useSignAndSendTransaction();

  const iconUrl = computed(() => {
    if (wallet.value?.adapter.name === SOLANA_WALLET_NAMES.Phantom) {
      return fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/solana/phantom.svg`);
    }
    return wallet.value?.adapter.icon;
  });

  const getDetails = async (txId: string) => {
    if (!props.awaitCommitment) return;

    const getDetailsConfig: GetVersionedTransactionConfig = {
      commitment: props.awaitCommitment,
      maxSupportedTransactionVersion: 0,
    };
    isFetchingTransactionDetails.value = true;
    try {
      return await getTransactionDetails(props.chainId, txId, getDetailsConfig);
    } catch {
      showToast({
        title: t('walletTransaction.transactionNotFoundError'),
        type: 'warning',
        durationSeconds: 5,
        link: `${SOLANA_FM_PREFIX}${txId}`,
        linkText: shortenString(txId),
      });
    } finally {
      isFetchingTransactionDetails.value = false;
    }
  };

  const confirm = () => {
    if (props.skipSendToBlockchain) {
      emit('confirm');
      return;
    }
    signWalletTransactionMutation(
      {
        chainId: props.chainId,
        unsignedTx: props.unsignedTx,
        walletAdapter: wallet.value!.adapter,
      },
      {
        onSettled: async (id) => {
          if (!id) return;
          const transactionDetails = await getDetails(id);
          emit('confirm', id, transactionDetails);
        },
        onError: (error) => {
          if (
            error instanceof WalletSendTransactionError &&
            isUserRejectionSolanaError(error)
          ) {
            return;
          }

          showToast({
            title: t('walletTransaction.transactionGenericError'),
            type: 'error',
            durationSeconds: 5,
          });
        },
      },
    );
  };
</script>

<template>
  <template v-if="wallet">
    <div
      v-if="isSigningTransaction || isFetchingTransactionDetails"
      class="flex flex-col items-center gap-6 p-6 pt-18"
    >
      <img v-lazy="iconUrl" class="h-32" :alt="wallet.adapter.name" />
      <span v-if="isFetchingTransactionDetails" class="font-bold text-2xl">
        {{ $t('walletTransaction.sendingTransaction') }}
      </span>
      <template v-else>
        <span class="font-bold text-2xl">
          {{ $t('walletTransaction.opening') }} {{ wallet.adapter.name }}...
        </span>
        <span class="text-gray-400 font-medium">
          {{ $t('walletTransaction.loadingTransactionMessage') }}
        </span>
      </template>
      <div class="relative w-full h-10 my-6">
        <loader variant="rainbow" border-width="border" size="size-10" />
      </div>
    </div>
    <div v-else class="flex flex-col items-center gap-6 pb-6 pt-10">
      <base-button
        variant="link"
        class="absolute top-6 right-9 z-10"
        @click="$emit('close')"
      >
        <base-icon name="x" size="size-5" class="text-gray-400" />
      </base-button>
      <h2 class="font-bold text-2xl">
        {{ $t('walletTransaction.confirmTransaction') }}
      </h2>
      <transaction-scan :scan-response="scanResponse">
        <img
          v-if="txSnapshotImageUrl"
          v-lazy="txSnapshotImageUrl"
          class="object-contain m-6"
        />
      </transaction-scan>
      <div class="flex flex-col min-w-60 gap-2">
        <base-button
          v-if="!warnings.length"
          variant="primary"
          size="small"
          @click="confirm"
        >
          <span>{{ $t('walletTransaction.confirm') }}</span>
        </base-button>
        <base-button variant="tertiary" size="small" @click="$emit('close')">
          {{ $t('cancel') }}
        </base-button>
        <base-button
          v-if="warnings.length && warningsType !== 'BLOCK'"
          variant="tertiary"
          size="small"
          additional-classes="text-red-350"
          @click="confirm"
        >
          {{ $t('walletTransaction.proceedAnyway') }}
        </base-button>
      </div>
    </div>
  </template>
</template>
