import { computed, ref, watch, type Ref } from 'vue';
import { refDebounced, useEventListener } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import type { ContentView } from 'dfx/edge/edge.did';
import { useClipboardToast, useToast } from '@/shared/model';
import { useConfirmationDialog } from '@/shared/ui/confirmation';
import { useUser } from '@/entities/user';
import { useConnectSolanaWallets, useWalletDialog } from '@/entities/wallets';
import {
  parseTransaction,
  useGetTransactionScanQuery,
  useTransactionDialog,
} from '@/entities/wallet-transaction';
import { contentReactionToReactionType } from '@/entities/reaction';
import { useCanvasWalletExtension } from './use-canvas-wallet-extension';
import { useAcceptTransactionMutation } from '../../api/use-accept-transaction.mutation';
import { useRejectTransactionMutation } from '../../api/use-reject-transaction.mutation';
import { useReportTransactionMutation } from '../../api/use-report-transaction.mutation';
import { useLogInteractionMutation } from '../../api/use-log-interaction.mutation';
import { useSignCanvasResponseMutation } from '../../api/use-sign-canvas-response.mutation';
import {
  CanvasInterface,
  type CanvasAppInfo,
  type UntrustedCanvasResponse,
} from '../../types';
// TODO: FSD issue: fix post feature in the future
import { usePostDialog } from '@/features/post';

export const useCanvasHost = (
  elementRef: Ref<HTMLIFrameElement | undefined>,
  canvasAppInfo: Ref<CanvasAppInfo>,
  inEditor: Ref<boolean>,
  content?: Ref<ContentView | undefined>,
) => {
  const chainId = ref<string>();
  const transactionId = ref<string>();
  const iframeSize = ref<CanvasInterface.User.ResizeRequest['payload']>();

  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const { copyToClipboard } = useClipboardToast();
  const { openConfirmationDialog, closeDialog } = useConfirmationDialog();
  const { currentUser, currentUserAvatarUrl } = useUser();
  const { wallet, connectedWalletAddress } = useConnectSolanaWallets();
  const { openConnectWalletDialog } = useWalletDialog();
  const {
    openAlreadyReportedWarningModal,
    openTransactionConfirmModal,
    openTransactionSuccessModal,
  } = useTransactionDialog();
  const { openCreatePostDialog } = usePostDialog();

  const appUrl = computed(() => canvasAppInfo.value?.originalUrl);

  const { mutateAsync: signCanvasResponseMutation } =
    useSignCanvasResponseMutation();
  const { mutateAsync: acceptTransactionAsyncMutation } =
    useAcceptTransactionMutation();
  const { mutateAsync: rejectTransactionAsyncMutation } =
    useRejectTransactionMutation();
  const { mutateAsync: reportTransactionAsyncMutation } =
    useReportTransactionMutation();
  const { mutate: logInteractionMutation } = useLogInteractionMutation();
  const { refetch: getTransactionScan } = useGetTransactionScanQuery(
    appUrl,
    chainId,
    transactionId,
  );
  const { handleReceiveMessage: handleReceiveWalletMessage } =
    useCanvasWalletExtension({
      appUrl,
      sendMessage: (e) => sendMessage(e),
    });

  const canvasUser = computed(() => {
    if (!currentUser.value) {
      return undefined;
    }
    const avatar =
      typeof currentUserAvatarUrl.value === 'string'
        ? currentUserAvatarUrl.value
        : undefined;
    return {
      id: currentUser.value.id.toText(),
      username: currentUser.value.username,
      avatar,
    };
  });

  const canvasContent = computed(() => {
    if (!content?.value) return undefined;

    return {
      id: content.value.id.toString(),
      portalId: content.value.portal.id.toString(),
      portalName: content.value.portal.name,
    };
  });

  const contentReactionType = computed(() => {
    const contentReaction = content?.value?.is_reactor[0];
    if (!contentReaction) return;
    return contentReactionToReactionType(contentReaction);
  });

  const latestContentReactionDebounced = refDebounced(contentReactionType, 100);

  const sendMessage = async <T extends CanvasInterface.BaseHostMessage>(
    message: UntrustedCanvasResponse<T>,
  ) => {
    if (!elementRef.value || !elementRef.value.contentWindow) {
      throw new Error('Element not found');
    }
    const sourceOrigin = elementRef.value.src;
    const signedMessage = await signCanvasResponseMutation(message);
    elementRef.value.contentWindow.postMessage(signedMessage, sourceOrigin);
  };

  const sendCoreMessage = async <T extends CanvasInterface.CoreHostMessage>(
    message: UntrustedCanvasResponse<T>,
  ) => sendMessage(message);

  const handleStartCanvasLifecycle = () => {
    sendCoreMessage({
      type: 'lifecycle:init-response',
      untrusted: {
        user: canvasUser.value,
        content: canvasContent.value,
      },
    });
  };

  const endCanvasLifecycle = () => {
    if (!elementRef.value?.contentWindow) return;
    sendCoreMessage({
      type: 'lifecycle:close',
    });
  };

  const handleOpenLink = (message: CanvasInterface.User.OpenLnkRequest) => {
    openConfirmationDialog({
      headerTitle: t('walletTransaction.redirectMessageTitle'),
      contentSlots: {
        default: {
          template: t('walletTransaction.redirectMessageDescription'),
        },
        cancelLabel: { template: t('cancel') },
        submitLabel: { template: t('continue') },
      },
      submit: () => {
        window.open(message.payload.url, '_link');
        closeDialog();
      },
    });
  };

  const handleInitialInteraction = async () => {
    if (
      !appUrl.value ||
      !content?.value?.id ||
      !canvasAppInfo.value?.autoStart
    ) {
      return;
    }
    logInteractionMutation({ url: appUrl.value, contentId: content.value.id });
  };

  const handleResize = (message: CanvasInterface.User.ResizeRequest) => {
    iframeSize.value = message.payload;
  };

  const handleCreatePost = async (
    message: CanvasInterface.User.CreatePostRequest,
  ) => {
    if (inEditor.value) {
      sendCoreMessage({
        type: 'user:create-post-response',
        untrusted: {
          success: false,
          errorReason: 'error',
          error: t('canvas.inEditorError'),
        },
      });
      return;
    }
    if (!message.payload.htmlContent) {
      sendCoreMessage({
        type: 'user:create-post-response',
        untrusted: {
          success: false,
          errorReason: 'error',
          error: t('canvas.emptyPostContentError'),
        },
      });
      return;
    }
    const response = await openCreatePostDialog(message.payload.htmlContent);
    if (!response) {
      sendCoreMessage({
        type: 'user:create-post-response',
        untrusted: {
          success: false,
          errorReason: 'user-cancelled',
        },
      });
      return;
    }

    sendCoreMessage({
      type: 'user:create-post-response',
      untrusted: {
        success: true,
        contentId: response.id,
      },
    });
  };

  const handleCopyToClipboard = async (
    message: CanvasInterface.User.CopyToClipboardRequest,
  ) => {
    try {
      await copyToClipboard(message.payload.content);
      sendCoreMessage({
        type: 'user:copy-to-clipboard-response',
        untrusted: {
          success: true,
        },
      });
    } catch (e) {
      const error = e as Error;
      sendCoreMessage({
        type: 'user:copy-to-clipboard-response',
        untrusted: {
          success: false,
          errorReason: 'error',
          error: error.message,
        },
      });
    }
  };

  const handleConnectWallet = async () => {
    const result = await openConnectWalletDialog();
    if (!result || !wallet.value || !connectedWalletAddress.value) {
      sendCoreMessage({
        type: 'user:connect-wallet-response',
        untrusted: {
          success: false,
          errorReason: 'user-cancelled',
        },
      });
      return;
    }

    if (canvasAppInfo.value?.reportCount) {
      const doContinue = await openAlreadyReportedWarningModal(
        canvasAppInfo.value?.reportCount,
      );
      if (!doContinue) return;
    }

    sendCoreMessage({
      type: 'user:connect-wallet-response',
      untrusted: {
        success: true,
        address: connectedWalletAddress.value,
        walletName: wallet.value.adapter.name.toString(),
        walletIcon: wallet.value.adapter.icon,
        walletUrl: wallet.value.adapter.url,
        walletSupportedTransactionVersions:
          wallet.value.adapter.supportedTransactionVersions ?? undefined,
      },
    });
  };

  const handleSignSendTransaction = async (
    message: CanvasInterface.User.SignAndSendTransactionRequest,
  ) => {
    if (!wallet.value) {
      showToast({
        title: t('walletTransaction.missingConnectedWalletError'),
        type: 'error',
        durationSeconds: 5,
      });
      return;
    }

    chainId.value = message.payload.chainId;
    transactionId.value = message.payload.unsignedTx;

    const { data: scanResponse } = await getTransactionScan();

    if (!scanResponse?.requestId) {
      showToast({
        title: t('walletTransaction.transactionGenericError'),
        type: 'error',
        durationSeconds: 5,
      });
      return;
    }

    // If empty, it means the transaction should await for confirmation
    // if transaction must not be awaited, it should be set to 'none' explicitly
    const awaitCommitment =
      message.payload.awaitCommitment === 'none'
        ? undefined
        : message.payload.awaitCommitment ?? 'confirmed';

    const confirmationResponse = await openTransactionConfirmModal({
      chainId: message.payload.chainId,
      unsignedTx: parseTransaction(message.payload.unsignedTx),
      scanResponse,
      awaitCommitment,
    });

    if (!confirmationResponse) {
      await rejectTransactionAsyncMutation({
        appUrl: appUrl.value!,
        scanResponse,
        txId: message.payload.unsignedTx,
      });
      sendCoreMessage({
        type: 'user:sign-send-transaction-response',
        untrusted: {
          success: false,
          errorReason: 'user-cancelled',
        },
      });
      return;
    }
    await acceptTransactionAsyncMutation({
      appUrl: appUrl.value!,
      scanResponse,
      signedTx: confirmationResponse.transactionId,
    });

    sendCoreMessage({
      type: 'user:sign-send-transaction-response',
      untrusted: {
        success: true,
        signedTx: confirmationResponse.transactionId,
      },
    });

    const transactionSuccessResult = await openTransactionSuccessModal(
      confirmationResponse.transactionId,
    );

    if (transactionSuccessResult) {
      await reportTransactionAsyncMutation({
        appUrl: appUrl.value,
        scanResponse,
        signedTx: confirmationResponse.transactionId,
        payload: transactionSuccessResult,
      });
    }
  };

  const handleReceiveMessage = async (event: MessageEvent) => {
    if (event.source !== elementRef.value?.contentWindow) return;
    const messageData = event.data;
    const message = CanvasInterface.parseCoreClientMessage(messageData);
    if (!message) {
      handleReceiveWalletMessage(event);
      return;
    }

    switch (message.type) {
      case 'lifecycle:init-request':
        handleStartCanvasLifecycle();
        break;
      case 'user:open-link-request':
        handleOpenLink(message);
        break;
      case 'user:initial-interaction-request':
        handleInitialInteraction();
        break;
      case 'user:resize-request':
        handleResize(message);
        break;
      case 'user:create-post-request':
        handleCreatePost(message);
        break;
      case 'user:copy-to-clipboard-request':
        handleCopyToClipboard(message);
        break;
      case 'user:connect-wallet-request':
        handleConnectWallet();
        break;
      case 'user:sign-send-transaction-request':
        handleSignSendTransaction(message);
        break;
      default:
        throw new Error(t('canvas.unknownMessageError'));
    }
  };

  useEventListener(window, 'message', handleReceiveMessage);

  const iframeSizeDebounced = refDebounced(iframeSize, 100);

  watch(latestContentReactionDebounced, (latestReaction) => {
    if (latestReaction) {
      sendCoreMessage({
        type: 'user:content-reaction-response',
        untrusted: {
          status: 'reacted',
          reaction: latestReaction,
        },
      });
    } else {
      sendCoreMessage({
        type: 'user:content-reaction-response',
        untrusted: {
          status: 'cleared',
        },
      });
    }
  });

  return {
    canvasAppInfo,
    iframeSize: iframeSizeDebounced,
    endCanvasLifecycle,
  };
};
