import axios from 'axios';
import { trackGAEvent } from '../utils/tracker';

export default {
  enrichUrl(url) {
    try {
      const lang = navigator.language;
      const host = location.host;
      const check_url = `https://enrich.dscvr.one/process?url=${url}&lang=${lang}&host=${host}`;
      return axios.get(check_url);
    } catch (error) {
      return null;
    }
  },
  verify(solve) {
    try {
      const check_url = `https://enrich.dscvr.one/verify?solve=${solve}`;
      return axios.post(check_url);
    } catch (error) {
      return null;
    }
  },
  translate(text, lang) {
    try {
      return axios
        .post('https://enrich.dscvr.one/translate', {
          text: text,
          lang: lang,
        })
        .then((res) => {
          if (res.data.data.translations.length) {
            trackGAEvent('translate_action', {
              source_lang: res.data.data.translations[0].detectedSourceLanguage,
              target_lang: lang,
            });
          }
          return res;
        });
    } catch (error) {
      return null;
    }
  },
  sendPhoneVerify(phone) {
    try {
      return axios.post('https://verify.dscvr.one/phone', {
        phone: phone,
      });
    } catch (error) {
      return null;
    }
  },
  confirmPhone(principal, code, sid) {
    try {
      return axios.post('https://verify.dscvr.one/phone/confirm', {
        principal: principal,
        code: code,
        sid: sid,
      });
    } catch (error) {
      return null;
    }
  },
};
