import { computed, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import * as bs58 from 'bs58';
import { VersionedTransaction } from '@solana/web3.js';
import type { ContentView } from 'dfx/edge/edge.did';
import { useToast } from '@/shared/model';
import { dscvrApi } from '@/shared/api';
import { useUser } from '@/entities/user';
import { useConnectSolanaWallets, useWalletDialog } from '@/entities/wallets';
import { useFramePostMutation } from '../../api/use-frame-post.mutation';
import type { Frame, FrameButton } from '../../types';
import { useRejectFrameTransactionMutation } from '../../api/use-reject-frame-transaction.mutation';
import { useAcceptFrameTransactionMutation } from '../../api/use-accept-frame-transaction.mutation';
import { createFrameFromHtml } from '../../lib/create-frame-from-html';
import { createHtmlDocument } from '../../lib/create-html-document';
import { signFramePostRequest } from '../../lib/sign-frame-post-request';
import { useTransactionDialog } from '@/entities/wallet-transaction';
import { useReportFrameTransactionMutation } from '../../api/use-report-frame-transaction.mutation';

export const useFrameTransaction = ({
  url,
  currentFrame,
  content,
  isDebug,
}: {
  url: Ref<string>;
  currentFrame: Ref<Frame | undefined>;
  content: Ref<ContentView | undefined> | undefined;
  isDebug: Ref<boolean | undefined>;
}) => {
  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const { currentUserPrincipal } = useUser();
  const { wallet, connectedWalletAddress } = useConnectSolanaWallets();
  const { openConnectWalletDialog } = useWalletDialog();
  const {
    openAlreadyReportedWarningModal,
    openTransactionConfirmModal,
    openTransactionSuccessModal,
    openTransactionErrorModal,
  } = useTransactionDialog();

  const { mutateAsync: reportFrameTransactionAsyncMutation } =
    useReportFrameTransactionMutation();

  const reportCount = computed(() => {
    // TODO (JM): Get the real report count from item
    return 0; //item.frameHash[url.value].reportCount
  });

  const transactedCount = computed(() => {
    // TODO (JM): Get the real transacted count from item
    return 0; //item.frameHash[url].transactedCount
  });

  const connectWallet = async (button: FrameButton) => {
    if (button.action !== 'tx') return;

    if (!currentFrame.value) {
      console.error('Frame tx: Frame not found');
      showToast({
        title: t('walletTransaction.transactionGenericError'),
        type: 'error',
        durationSeconds: 5,
      });
    }
    if (!button.target) {
      console.error('Frame tx: Button target must be set for tx action');
      showToast({
        title: t('walletTransaction.transactionGenericError'),
        type: 'error',
        durationSeconds: 5,
      });
      return;
    }

    if (reportCount.value > 0) {
      const doContinue = await openAlreadyReportedWarningModal(
        reportCount.value,
      );
      if (!doContinue) return;
    }

    return await openConnectWalletDialog();
  };

  const confirmTransaction = async (
    frameResponse: dscvrApi.frame.FrameTransactionResponse,
  ) => {
    if (!wallet.value) {
      showToast({
        title: t('walletTransaction.missingConnectedWalletError'),
        type: 'error',
        durationSeconds: 5,
      });
      return;
    }
    const scanResponse = frameResponse.scanResponse;
    scanResponse.requestId;
    if (!scanResponse || 'error' in scanResponse) {
      console.error('Frame tx: Scan response error', scanResponse?.error);
      showToast({
        title: t('walletTransaction.transactionGenericError'),
        type: 'error',
        durationSeconds: 5,
      });
      return;
    }

    if (!frameResponse.params) {
      openTransactionErrorModal(scanResponse);
      return;
    }

    const txUint8Array = bs58.decode(frameResponse.params.tx);
    const unsignedTx = VersionedTransaction.deserialize(txUint8Array);

    const confirmationResponse = await openTransactionConfirmModal({
      chainId: frameResponse.chainId,
      unsignedTx,
      txSnapshotImageUrl: currentFrame.value?.imageUrl,
      scanResponse,
      awaitCommitment: 'confirmed',
    });

    if (!confirmationResponse) {
      if (!currentFrame.value) return;
      useRejectFrameTransactionMutation(
        currentFrame.value.url,
        frameResponse.params.tx,
      );
      return;
    }

    return { transactionId: confirmationResponse.transactionId, scanResponse };
  };

  const handleTransaction = async (
    button: FrameButton,
    frameRequest: dscvrApi.frame.FramePostRequest,
    frameResponse: dscvrApi.frame.FrameTransactionResponse,
    startFrameLoading: () => void,
  ) => {
    if (!currentFrame.value) {
      throw new Error('Frame not found');
    }

    const confirmResponse = await confirmTransaction(frameResponse);

    if (!confirmResponse) {
      return;
    }

    const { transactionId, scanResponse } = confirmResponse;

    useAcceptFrameTransactionMutation(currentFrame.value.url, transactionId);

    const postUrl =
      button.post_url || currentFrame.value.postUrl || currentFrame.value.url;

    startFrameLoading();
    const transactionCompleteRequest = await signFramePostRequest({
      postUrl,
      actionType: 'post',
      actionPayload: {
        url: currentFrame.value.url,
        contentId: content?.value?.id.toString(),
        state: currentFrame.value.state,
        dscvrId: currentUserPrincipal.value?.toText(),
        timestamp: Number(Date.now()),
        buttonIndex: button.index,
        inputText: frameRequest.body.untrustedData?.inputText,
        address: frameRequest.body.untrustedData?.address,
        transactionId,
      },
      isDebug: isDebug.value,
      blowfishRequestId: scanResponse.requestId,
    });

    const transactionCompleteResponse = await useFramePostMutation(
      transactionCompleteRequest,
    );

    if (transactionCompleteResponse.type === 'post') {
      const transactionSuccessResult = await openTransactionSuccessModal(
        transactionId,
      );

      if (transactionSuccessResult) {
        await reportFrameTransactionAsyncMutation({
          frameUrl: currentFrame.value.url,
          transactionId,
          payload: transactionSuccessResult,
        });
      }

      const nextFrame = createFrameFromHtml(
        createHtmlDocument(transactionCompleteResponse.html),
        currentFrame.value.url,
      );
      return { request: transactionCompleteRequest, nextFrame };
    }

    console.error('Frame tx: frame server response is not post');
    showToast({
      title: t('walletTransaction.transactionGenericError'),
      type: 'error',
      durationSeconds: 5,
    });
  };

  return {
    connectedWalletAddress,
    reportCount,
    transactedCount,
    connectWallet,
    handleTransaction,
  };
};
