<template>
  <div ref="main" class="flex flex-col h-full text-white md:mx-0">
    <div class="relative flex items-center justify-between mb-8">
      <div class="flex items-center gap-3">
        <user-avatar v-if="currentUser" :item="currentUser" size="w-8 h-8" />
        <img
          v-else
          alt="user icon"
          class="inline w-8 h-8 border-gray-300 rounded-full shadow-lg border-1"
          :src="getAvatarIcon(currentUser.username, '374151')"
        />
        <h1 class="text-lg font-semibold leading-6">{{ $t('postTo') }}</h1>
        <div
          class="flex items-center p-1.5 rounded-md bg-white/[0.08] gap-2 cursor-pointer hover:bg-gray-700"
          :class="{
            'opacity-50 pointer-events-none select-none': editPost,
          }"
          @click.stop="openPortalsMenu"
        >
          <base-icon
            v-if="portal_id == 'none'"
            name="user-framed"
            size="w-5 h-5 text-gray-400"
          />
          <img
            v-else
            alt="icon"
            class="inline mr-2 rounded-lg size-8"
            :src="getPortalIcon(share_to_portal)"
          />
          <span
            v-if="portal_id == 'none'"
            class="text-sm font-semibold leading-[18px]"
          >
            {{ $t('myProfile') }}
          </span>
          <span v-else class="text-sm leading-[18px]">
            {{ share_to_portal.name }}
          </span>
          <base-icon name="arrow-down" size="w-[10px] h-[7px]" />
        </div>
      </div>
      <div class="flex items-center gap-6">
        <button
          type="button"
          class="flex items-center justify-center w-8 h-8 rounded-md hover:bg-white/[0.16]"
          @click="close"
        >
          <base-icon
            name="close"
            class="text-[#D1D5DB] cursor-pointer"
            size="w-6 h-6"
          />
        </button>
      </div>
      <div
        v-if="portalsSelectionOpen"
        v-click-outside="() => (portalsSelectionOpen = false)"
        class="absolute w-[240px] h-[352px] bg-[#131720] top-[50px] z-50 flex flex-col portals-menu border border-[#333B47]/[0.5] rounded-xl px-2"
      >
        <div
          class="bg-black/[0.24] border border-[#333B47]/[0.32] rounded-md relative my-2"
        >
          <base-icon
            name="search"
            class="absolute text-gray-400 translate-y-1/3 -translate-x-1/4 left-4"
          />
          <input
            v-model="keyword"
            type="text"
            :placeholder="$t('searchPortals')"
            class="w-full bg-transparent border-none focus:outline-none focus:ring-0 pl-11"
          />
        </div>
        <div
          class="flex pt-2 pb-4 cursor-pointer gap-x-2"
          @click="setPortal(false)"
        >
          <base-icon name="user-framed" size="w-5 h-5 text-gray-400" />
          <span class="text-sm font-semibold leading-[18px]">
            {{ $t('myProfile') }}
          </span>
        </div>
        <h1
          v-if="favorites.length && !keyword"
          class="mb-3 text-sm font-medium leading-5 text-gray-400"
        >
          {{ $t('favorites') }}
        </h1>
        <div
          v-if="keyword"
          ref="portalsListRef"
          class="flex-1 overflow-y-auto thin-scrollbar portals"
        >
          <div
            class="relative w-full"
            :style="{
              height: `${totalSize}px`,
            }"
          >
            <div
              v-for="virtualRow in virtualRows"
              :key="virtualRow.index"
              :data-index="virtualRow.index"
              class="absolute top-0 left-0 w-full"
              :style="{
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }"
            >
              <portal-select-item
                :portal-view="portals[virtualRow.index]"
                :selected="portal_id == portals[virtualRow.index]"
                @set-portal="setPortal"
              />
            </div>
          </div>
        </div>
        <div
          v-else-if="!keyword && favorites.length"
          class="flex-1 overflow-y-auto thin-scrollbar portals"
        >
          <portal-select-item
            v-for="p in favorites"
            :key="p"
            :portal-view="p"
            :selected="portal_id == p.id"
            @set-portal="setPortal"
          />
        </div>
      </div>
    </div>
    <form class="flex flex-col flex-1 md:min-h-[384px]">
      <post-form-fields
        ref="postForm"
        :model-value="post.body"
        :editing="editing"
        :poll="poll"
        :tags="tags"
        v-model:is-nsfw="post.is_nsfw"
        v-model:disable-comments="post.disable_comments"
        @update:tags="setTags"
        @update:model-value="bodyUpdated"
        @error="showToastMessage"
        @scroll-into-view="scrollContentIntoView"
        @submit="onSubmit"
      >
        <template #editorFooter>
          <error-display
            :errors="errors"
            :error-message="errorMessage"
            :local-errors="localErrors"
            class="transition-all duration-200"
            :class="{
              'max-h-0 opacity-0 hidden': !hasLocalErrors,
              'max-h-40 opacity-100 pt-2 pb-4 block': hasLocalErrors,
            }"
          />
        </template>
      </post-form-fields>
    </form>
  </div>
</template>

<script>
  import { ref, computed, watchEffect } from 'vue';
  import { mapGetters } from 'vuex';
  import { parseSocietyRsErrorType } from '@/shared/lib';
  import { ActionTypes } from '@/store';
  import enrichService from '@/services/enrich.js';
  import mixinAutoResize from '@/components/mixins/autoResize.js';
  import PortalSelectItem from '@/components/portals/PortalSelectItem.vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import PostFormFields from './post-form-fields/PostFormFields.vue';
  import ErrorDisplay from '@/components/ErrorDisplay.vue';
  import { useFormatHTMLContent } from '@/composables';
  import {
    getPortalIcon,
    usePortalUserStatus,
    useSearchPortalsQuery,
  } from '@/entities/portal';
  import {
    useCreateContentMutation,
    useUpdateContentMutation,
  } from '@/entities/post';
  import { useQueryClient } from '@tanstack/vue-query';
  import { useFeed, useToast } from '@/shared/model';
  import { lang, trackEvent } from '@/utils';
  import { useChainBehavior } from '@/entities/user-setting';
  import { isEqual, uniqBy } from 'lodash-es';
  import { refDebounced } from '@vueuse/core';
  import { useVirtualizer } from '@tanstack/vue-virtual';

  export default {
    name: 'create-post-form',
    components: {
      PortalSelectItem,
      UserAvatar,
      PostFormFields,
      ErrorDisplay,
    },
    mixins: [mixinAutoResize],
    props: {
      portalView: {
        type: Object,
        default: () => {},
      },
      tab: {
        type: Number,
        default: 1,
      },
      editPost: {
        type: Object,
        default: () => {},
      },
      initialContent: {
        type: String,
        default: null,
      },
    },
    emits: ['create-post', 'edit-post', 'content-added', 'close'],
    setup() {
      const { showToast } = useToast();
      const { favorites } = usePortalUserStatus();
      const { contentPreset } = useChainBehavior();
      const { mutateAsync: createContent } = useCreateContentMutation();
      const { mutateAsync: updateContent } = useUpdateContentMutation();
      const queryClient = useQueryClient();
      const { queryKeyByContext } = useFeed();

      const keyword = ref('');
      const keywordDebounced = refDebounced(keyword, 500);
      const queryParams = computed(() => ({
        sort_by: [{ Name: null }],
        page_size: 10n,
        page: 0n,
        query: keywordDebounced.value,
        chain_filter: contentPreset.value,
        sort_direction: [{ Ascending: null }],
      }));

      const {
        data: searchPortals,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
      } = useSearchPortalsQuery(queryParams);

      const portals = computed(() => {
        const pages = searchPortals.value?.pages ?? [];
        const items = pages.flatMap((i) => i.items ?? []);
        return uniqBy(items, 'id');
      });

      const portalsListRef = ref(null);

      const virtualizerOptions = computed(() => ({
        count: portals.value.length,
        estimateSize: () => 50,
        getScrollElement: () => portalsListRef.value,
      }));

      const rowVirtualizer = useVirtualizer(virtualizerOptions);

      const virtualRows = computed(() =>
        rowVirtualizer.value.getVirtualItems(),
      );

      const totalSize = computed(() => rowVirtualizer.value.getTotalSize());

      watchEffect(() => {
        const [lastItem] = [...virtualRows.value].reverse();
        if (!lastItem) {
          return;
        }
        if (lastItem.index >= portals.value.length - 1) {
          if (!isFetchingNextPage.value && hasNextPage.value) {
            fetchNextPage();
          }
        }
      });

      return {
        createContent,
        favorites,
        keyword,
        portals,
        queryClient,
        queryKey: queryKeyByContext,
        showToast,
        updateContent,
        portalsListRef,
        rowVirtualizer,
        virtualRows,
        totalSize,
      };
    },
    data() {
      return {
        portal_id: this.editPost
          ? this.editPost.portal.id
          : this.portalView
          ? this.portalView.id
          : 'none',
        share_to_portal: this.editPost
          ? this.editPost.portal
          : this.portalView
          ? this.portalView
          : null,
        openTab: Number(this.tab),
        localErrors: [],
        errorMessage: '',
        errors: [],
        title_set: false,
        tags: this.editPost ? this.editPost.tags.map((t) => t.name) : [],
        poll: {
          choices: ['', ''],
          days: '7',
          kind: { Traditional: null },
        },
        post: {
          title: '',
          body: this.editPost
            ? this.editPost.body.replace('‰HTML', '')
            : this.initialContent ?? '',
          url: '',
          icon_url: '',
          is_nsfw: this.editPost ? this.editPost.is_nsfw : false,
          disable_comments: false,
        },
        formSumbitted: false,
        dragActive: false,
        dragEvents: ['dragenter', 'dragover', 'dragleave', 'drop'],
        showOldView: false,
        portalsSelectionOpen: false,
        settingsMenuClass: 'top-[-105px] sm:top-[-105px]',
        getPortalIcon,
      };
    },

    computed: {
      ...mapGetters({
        currentUser: 'auth/me',
      }),
      editing() {
        return !!this.editPost;
      },
      slug() {
        return this.post.title
          .toLowerCase()
          .replace(/[^\w ]+/g, '')
          .replace(/ +/g, '-');
      },
      hasLocalErrors() {
        return !this.localErrors.length == 0;
      },
      contentExists() {
        return !this.$refs.postForm.isEmpty;
      },
      postReady() {
        return !!(
          this.contentExists && (this.openPoll ? this.poll.days > 0 : true)
        );
      },
    },
    watch: {
      post: {
        handler(val) {
          if (this.isValidUrl(val.url) && !val.title && !this.title_set) {
            enrichService.enrichUrl(val.url).then((response) => {
              this.title_set = true;
              if (response.data && response.data.title) {
                this.post.title = response.data.title;
              }
            });
          }
          this.$emit('content-added', this.post);
        },
        deep: true,
      },
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
      // drag events
      document.body.addEventListener(
        'dragenter',
        () => (this.dragActive = true),
      );
      document.body.addEventListener('drop', () => (this.dragActive = false));

      this.dragEvents.forEach((eventName) => {
        document.body.addEventListener(eventName, this.preventDefaults);
      });

      const shareIt = this.checkShareIt(this.$route);
      if (shareIt) {
        this.post.url = shareIt.url ?? '';
        this.post.body = shareIt.text ?? '';
        this.post.title = shareIt.title ?? '';

        if (this.post.url) {
          this.toggleTabs(2);
        }
      }
    },
    unmounted() {
      this.dragEvents.forEach((eventName) => {
        document.body.removeEventListener(eventName, this.preventDefaults);
      });
    },
    methods: {
      showToastMessage(message) {
        this.showToast({
          type: 'error',
          title: message,
          durationSeconds: 5,
        });
      },
      isOverFlow() {
        if (!this.$refs.main) return false;
        return this.$refs.main.clientHeight > window.innerHeight - 200;
      },
      handleResize() {
        if (this.isOverFlow()) {
          this.settingsMenuClass = 'top-[-105px] sm:top-[-105px]';
        } else {
          this.settingsMenuClass = 'top-[-105px] sm:top-[40px]';
        }
      },
      preventDefaults(e) {
        e.preventDefault();
      },
      bodyUpdated(sanitizedHTML) {
        this.post.body = sanitizedHTML;
        if (!this.showOldView) {
          this.scrollContentIntoView();
        }
      },
      toggleInputView(checked) {
        this.post.body = '';
        this.showOldView = checked;
      },
      scrollContentIntoView() {
        const isFirefox = navigator.userAgent.match(/firefox|fxios/i);
        const selection = window.getSelection();
        if (!selection.rangeCount) {
          return;
        }
        const firstRange = selection.getRangeAt(0);
        if (firstRange.commonAncestorContainer === document) {
          return;
        }

        const tempAnchorEl = document.createElement('br');
        firstRange.insertNode(tempAnchorEl);

        if (!isFirefox) {
          tempAnchorEl.scrollIntoViewIfNeeded();
        } else {
          tempAnchorEl.scrollIntoView({
            block: 'center',
          });
        }

        tempAnchorEl.remove();
      },
      isValid() {
        this.localErrors = [];

        if (!this.post.url.replace(/\s/g, '').length && this.openTab === 2) {
          this.localErrors.push({
            field: 'url',
            errors: [this.$t('requiredUrlError')],
          });
        }

        if (
          this.post.url &&
          !this.isValidUrl(this.post.url) &&
          this.openTab === 2
        ) {
          this.localErrors.push({
            field: 'url',
            errors: [this.$t('invalidUrlError')],
          });
        }

        if (
          this.post.url &&
          this.isValidUrl(this.post.url) &&
          this.openTab === 2
        ) {
          if (!/^(?:f|ht)tps?:\/\//.test(this.post.url)) {
            this.post.url = 'http://' + this.post.url;
          }
        }

        if (this.openPoll) {
          if (this.poll.choices.length < 2) {
            this.localErrors.push({
              field: 'poll',
              errors: [this.$t('pollMinQuestionsError')],
            });
          }

          for (let i = 0; i < this.poll.choices.length; i++) {
            const c = this.poll.choices[i];

            if (!c) {
              this.localErrors.push({
                field: 'poll',
                errors: [this.$t('pollEnptyQuestionError')],
              });
            }

            if (c && c.length > 100) {
              this.localErrors.push({
                field: 'poll',
                errors: [this.$t('pollQuestionMaxCharsError')],
              });
            }
          }

          if (this.poll.days <= 0) {
            this.localErrors.push({
              field: 'poll',
              errors: [this.$t('pollRequiredVotingPeriodError')],
            });
          }
        }

        if (!this.contentExists && (this.openTab === 1 || this.openPoll)) {
          this.$refs.postForm?.executeEmptyMessageAnimation();
          this.showToastMessage(this.$t('postError'));
        }

        return !this.hasLocalErrors;
      },
      setTags(val) {
        this.tags = val;
      },
      async setPortal(p) {
        if (!p) {
          this.portal_id = 'none';
          this.portalsSelectionOpen = false;
          return;
        }
        this.portal_id = p ? p.id : 'none';
        this.share_to_portal = p;
        this.keyword = '';
        this.portalsSelectionOpen = false;
      },
      toggleTabs(tabNumber) {
        this.openTab = tabNumber;
        this.dragActive = false;
      },
      onEditorChange({ html }) {
        this.post.body = html;
      },
      getPollData() {
        if (isEqual(this.poll.choices, ['', ''])) return [];
        return [
          {
            ...this.poll,
            days: BigInt(this.poll.days),
          },
        ];
      },
      onSubmit() {
        const { storeMediaSources } = useFormatHTMLContent();
        if (this.editPost) {
          this.$store.dispatch(`posts/${ActionTypes.SET_IS_LOADING}`, true);
          storeMediaSources(this.post.body).then((body) => {
            this.post.body = body;
            this.updateContent(
              {
                id: this.editPost.id,
                body: `‰HTML${this.post.body}`,
                tags: this.tags,
                disableComments: [this.post.disable_comments],
                isNsfw: [this.post.is_nsfw],
                updatePoll: this.getPollData(),
              },
              {
                onSuccess: (data) => {
                  trackEvent('post_action', 'edited');
                  if (data?.status == 'happy') {
                    this.queryClient.invalidateQueries({
                      queryKey: this.queryKey,
                    });
                    this.showToast({
                      type: 'success',
                      title: this.$t('postUpdated'),
                      durationSeconds: 5,
                    });
                    this.$emit('edit-post', data.result[0]);
                  } else {
                    this.errorMessage = result.message;
                    this.errors = result.errors[0];
                  }
                },
                onSettled: () => {
                  this.$store.dispatch(
                    `posts/${ActionTypes.SET_IS_LOADING}`,
                    false,
                  );
                },
              },
            );
          });
        } else {
          this.formSumbitted = true;
          this.isValid();
          if (this.postReady) {
            this.$store.dispatch(`posts/${ActionTypes.SET_IS_LOADING}`, true);
            storeMediaSources(this.post.body).then(async (body) => {
              this.post.body = this.showOldView ? body : `‰HTML${body}`;
              this.poll.days = parseInt(this.poll.days);
              const postLang = await lang.getContentLang();
              this.createContent(
                {
                  ...this.post,
                  lang: postLang,
                  disable_comments: [this.post.disable_comments],
                  slug: this.slug,
                  portal_id: this.portal_id !== 'none' ? [this.portal_id] : [],
                  content_type: 'post',
                  parent_id: [],
                  poll: this.getPollData(),
                  tags: this.tags,
                },
                {
                  onSuccess: async (data) => {
                    if (data?.status === 'sad') {
                      if (
                        parseSocietyRsErrorType(data?.error_code) ===
                        'Unauthorized'
                      ) {
                        this.showToastMessage(this.$t('portalPermissionError'));
                      }
                    } else if (data?.status == 'happy') {
                      trackEvent('post_action', 'created');
                      this.post = {
                        title: '',
                        body: '',
                        url: '',
                        icon_url: '',
                        is_nsfw: false,
                        disable_comments: false,
                        poll: {
                          questions: ['', ''],
                          days: 0n,
                          kind: { Traditional: null },
                        },
                      };
                      this.showToast({
                        type: 'success',
                        title: this.$t('postCreated'),
                        durationSeconds: 5,
                      });
                      this.queryClient.invalidateQueries({
                        queryKey: this.queryKey,
                      });
                      this.$emit('create-post', data.result[0]);
                    } else {
                      this.errorMessage = data.message.startsWith(
                        'PERMISSION_ERROR_CREATE_CONTENT_POST',
                      )
                        ? this.$t('portalPermissionError')
                        : data.message;

                      this.errors = data.errors[0];
                    }
                  },
                  onSettled: () => {
                    this.$store.dispatch(
                      `posts/${ActionTypes.SET_IS_LOADING}`,
                      false,
                    );
                  },
                },
              );
            });
          }
          this.formSumbitted = false;
        }
      },
      close() {
        this.$emit('close');
      },
      openPortalsMenu() {
        this.portalsSelectionOpen = true;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .portals-menu {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.04),
        rgba(255, 255, 255, 0.04)
      ),
      #1c212e;
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.4);
  }
</style>
